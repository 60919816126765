import { useEffect } from 'react';

import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import PageLoading from '@zenbusiness/zui/PageLoading';

const Logout = () => {
  const { logout } = useAuthentication();

  useEffect(() => {
    logout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageLoading />;
};

export default Logout;
